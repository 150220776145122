import React, { useState } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const HomePage = () => {
  const [wallet, setWallet] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate('/dashboard', { state: { wallet } });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundImage: 'url(/background.png)', 
        backgroundSize: 'cover', 
        backgroundPosition: 'center', 
      }}
    >
      <Typography variant="h4" component="h1" gutterBottom sx={{ color: 'white' }}>
        NATPLANETS
      </Typography>
      <form onSubmit={handleSubmit}>
      <TextField
        label="Your Bitcoin wallet"
        variant="outlined"
        fullWidth
        value={wallet}
        onChange={(e) => setWallet(e.target.value)}
        required
        sx={{
            maxWidth: '1500px',
            input: { color: 'white' },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "white", 
              },
              "&:hover fieldset": {
                borderColor: "white", 
              },
              "&.Mui-focused fieldset": {
                borderColor: "white", 
              }
            }
          }} 
          InputLabelProps={{
            style: { color: 'white' }, 
          }}
          inputProps={{
            style: { color: 'white' }, 
          }}
        />

            <Button
            type="submit"
            variant="contained"
            sx={{
                mt: 2, 
                backgroundColor: 'white', 
                color: 'purple', 
                '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.8)', 
                },
                alignSelf: 'center',
            }}
            >
            Enter in the universe
            </Button>
      </form>
    </Box>
  );
};

export default HomePage;
