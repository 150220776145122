import React, { useEffect, useState } from 'react';
import { useLocation,useNavigate } from 'react-router-dom';
import { Box, Typography, Card, CardMedia, CardContent, Grid, Pagination, IconButton, Link,Button } from '@mui/material';

const DashboardPage = () => {
  const location = useLocation();
  const navigate = useNavigate(); 
  const { wallet } = location.state || {};
  const [tokens, setTokens] = useState([]);
  const [page, setPage] = useState(1);
  const [rareteGlobale, setRareteGlobale] = useState(0);
  const lambdaApiUrl = `https://rga98jkab1.execute-api.us-east-2.amazonaws.com/default/okx`;
  const [sortOrder, setSortOrder] = useState('descending'); 

  const toggleSortOrder = () => {
    setSortOrder(sortOrder === 'ascending' ? 'descending' : 'ascending');
  };
  const sortTokens = (tokens) => {
    return tokens.sort((a, b) => {
      if (sortOrder === 'ascending') {
        return a.rarete - b.rarete;
      } else {
        return b.rarete - a.rarete;
      }
    });
  };
  const itemsPerPage = 10;
  const raretes = {
    Background: {
      Stars: 20.29,
      Planets: 18.80,
      Moon: 18.70,
      Emerald: 16.77,
      Epic: 14.43,
      Fusion: 8.53,
      Lighthole: 2.48,
    },
    Planets: {
      Blue: 20.78,
      Green: 19.30,
      Orange: 19.15,
      Gray: 15.53,
      Red: 14.19,
      Purple: 7.54,
      Gold: 7.54,
    },
    Continents: {
      Classicgreen: 11.36,
      Classicred: 10.52,
      Horizontalgreen: 10.02,
      Middlegreen: 9.57,
      Middlered: 9.47,
      Horizontalred: 9.08,
      Islandred: 8.73,
      Islandgreen: 8.38,
      Yinred: 7.19,
      Yingreen: 6.75,
      Disasterred: 4.66,
      Disastergreen: 4.27,
    },
    Rings: {
      Noring: 22.97,
      Silverring3: 14.78,
      Silverring1: 14.34,
      Silverring2: 14.24,
      Goldring2: 10.71,
      Goldring: 10.42,
      Goldring3: 9.57,
      Diamondring: 2.98,
    }
  };

  const calculerRareteItem = (attributes) => {
    let item_rarity = 100;
    let levelMultiplier = 1;
    
    
    attributes.forEach(attr => {
      const traitType = attr.trait_type.replace(/\s+/g, '');
      const traitValue = attr.value.replace(/\s+/g, '');
      
     
      if (traitType !== "Level" && raretes[traitType] && raretes[traitType][traitValue]) {
        item_rarity -= raretes[traitType][traitValue];
      }
      
      
      if (traitType === "Level") {
        const level = parseInt(traitValue);
        if (level >= 1 && level <= 9) {
          levelMultiplier += (level - 1) * 0.1;
        } else if (level >= 10) {
          levelMultiplier += 0.8 + ((level - 9) * 0.1);
        }
      }
    });
  
    return item_rarity * levelMultiplier;
  };
  


  useEffect(() => {

    if (!wallet) {
      navigate('/');
    } else {

    const fetchTokens = async () => {
      try {
        const response = await fetch(lambdaApiUrl, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ wallet }),
        });
        if (!response.ok) throw new Error(`Lambda API Error: ${response.statusText}`);
        const tokensData = await response.json(); 

        if (Array.isArray(tokensData)) {
          let processedTokens = tokensData.map(token => ({
            id: token.id,
            name: token.meta.name,
            image: token.meta.high_res_img_url,
            attributes: token.meta.attributes,
            rarete: calculerRareteItem(token.meta.attributes),
          }));
          processedTokens = sortTokens(processedTokens); 
          setTokens(processedTokens);
          const totalRarity = processedTokens.reduce((acc, token) => acc + token.rarete, 0);
          setRareteGlobale(totalRarity); 
        } else {
          console.error('Unexpected response format:', tokensData);
          setTokens([]);
          setRareteGlobale(0);
        }
      } catch (error) {
        console.error("Error fetching or matching tokens:", error);
      }
    };
    fetchTokens();

  }

  }, [wallet,navigate,sortOrder]);
  
  


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const totalPages = Math.ceil(tokens.length / itemsPerPage);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minHeight: '100vh',
      color: 'white',
      p: 3,
      backgroundImage: 'url(/background.png)',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundAttachment: 'fixed',
    }}>
      <Typography variant="h1" component="h1" gutterBottom sx={{ color: 'white', width: '100%', textAlign: 'center' }}>
      Natplanets Cockpit
      </Typography>

      <Typography variant="body1" sx={{ mt: 2, mb: 2, textAlign: 'center', width: '80%' }}>
      Monitor your "Spatial Power". Combining all the rarities in your wallet will allow you to obtain a greater SP.
      </Typography>

                <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mb: 4 }}>
            <Link href="https://twitter.com/DMTNatplanets" target="_blank" rel="noopener noreferrer">
                <IconButton>
                <img src="/twitter.png" alt="Twitter" style={{ width: 24, height: 24 }} />
                </IconButton>
            </Link>
            <Link href="https://discord.gg/BUTH33GF2M" target="_blank" rel="noopener noreferrer">
                <IconButton>
                <img src="/discord.png" alt="Discord" style={{ width: 24, height: 24 }} />
                </IconButton>
            </Link>
            <Link href="https://magiceden.io/ordinals/marketplace/natplanets" target="_blank" rel="noopener noreferrer">
                <IconButton>
                <img src="/magic.png" alt="MagicEden" style={{ width: 35, height: 24 }} />
                </IconButton>
            </Link>
            </Box>

      <Typography variant="h3" sx={{ mb: 4, textAlign: 'center', width: '100%', fontWeight: 'normal' }}>
        Spatial Power:
      </Typography>
      <Typography variant="h3" sx={{ mb: 4, textAlign: 'center', width: '100%', fontWeight: 'bold' }}>
      {rareteGlobale.toFixed(2)} SP
      </Typography>
      <Typography variant="h4" sx={{ mt: 2, mb: 2, textAlign: 'center', width: '80%' }}>
       My Natplanets:
      </Typography>

      <Button onClick={toggleSortOrder}   sx={{
          mb: 2,
          color: 'white', 
          borderColor: 'white', 
          '&:hover': {
            borderColor: 'white', 
            backgroundColor: 'rgba(255,255,255,0.2)' 
          }
        }}
      >
        {sortOrder === 'ascending' ? 'Sort by Highest Rarity' : 'Sort by Lowest Rarity'}
      </Button>
      <Grid container spacing={2} justifyContent="center">
        {tokens.slice((page - 1) * itemsPerPage, page * itemsPerPage).map((token, index) => (
          <Grid item xs={12} sm={6} md={4} lg={2.4} key={index}>
            <Card sx={{ maxWidth: 345, bgcolor: 'rgba(255,255,255,0.1)', border: '2px solid white', borderRadius: '8px' }}>
              <CardMedia
                component="img"
                height="140"
                image={token.image}
                alt={token.name}
              />
              <CardContent sx={{ bgcolor: '#5a2e66', borderRadius: '4px' }}>
                <Typography gutterBottom variant="h5" component="div" sx={{ color: 'white' }}>
                  {token.name}
                </Typography>
                <Typography variant="body2" sx={{
                    mt: 1,
                    textAlign: 'center',
                    color: 'white',
                    border: '1px solid white',
                    borderRadius: '4px',
                    padding: '4px',
                    width: 'fit-content',
                    margin: 'auto'
                }}>
                Rarity: {token.rarete.toFixed(2)} SP
                </Typography>
                <Grid container spacing={1} sx={{ p: 1, mt: 2, justifyContent: 'center' }}>
                    {token.attributes.map((attribute, index) => (
                        <Grid item xs={6} key={index} sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center', 
                        justifyContent: 'center',
                        bgcolor: 'rgba(255,255,255,0.1)',
                        borderRadius: '0px',
                        p: 0.5,
                        border: '1px solid white',
                        boxSizing: 'border-box', 
                        width: '100%', 
                        textAlign: 'center' 
                        }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'white' }}>
                            {attribute.trait_type}
                        </Typography>
                        <Typography variant="body2" sx={{ color: 'white' }}>
                            {attribute.value}
                        </Typography>
                        </Grid>
                    ))}
                    </Grid>

              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Box sx={{ mt: 3 }}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChangePage}
          color="primary"
          sx={{
            '& .MuiPaginationItem-root': {
              color: 'white', 
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default DashboardPage;
